<template>
  <div>
    <div class="router-view-container">
      <h1 class="page_title mb-5">{{ $route.meta.title }}</h1>

      <div v-if="is_API_loaded" class="space-y-10">
        <section class="wrap">
          <div class="leftBlock">
            <h3 class="h3 sm:mt-4">標籤</h3>
            <p>替商品添加標籤，可利於後台查詢商品、指定優惠。</p>
          </div>
          <div class="rightBlock">
            <div class="flex items-center justify-between">
              <h3 class="h3">標籤列表</h3>
              <button @click="open_drawer('new', 'tag')" class="font-medium text-success flex items-center space-x-1">
                <svg-icon icon-class="add" className="success sm"></svg-icon>
                <span>新增標籤</span>
              </button>
            </div>
            <hr />
            <div v-if="tagsList.length > 0" class="flex items-center flex-wrap">
              <div v-for="item in tagsList" :key="item.sptno" class="mr-4 mb-4">
                <vs-button size="large" border @click="open_drawer('edit', 'tag', item.title, item.sptno)">{{ item.title }}</vs-button>
              </div>
            </div>

            <div v-else class="text-center pb-4">尚無任何標籤</div>
          </div>
        </section>
        <hr />
        <section class="wrap">
          <div class="leftBlock">
            <h3 class="h3 sm:mt-4">供應商</h3>
            <p>替商品添加供應商，可利於後台查詢商品、指定優惠。</p>
          </div>
          <div class="rightBlock">
            <div class="flex items-center justify-between">
              <h3 class="h3">供應商列表</h3>
              <button @click="open_drawer('new', 'vendor')" class="font-medium text-success flex items-center space-x-1">
                <svg-icon icon-class="add" className="success sm"></svg-icon>
                <span>新增供應商</span>
              </button>
            </div>
            <hr />
            <div v-if="vendorsList.length > 0" class="flex items-center flex-wrap">
              <div v-for="item in vendorsList" :key="item.spmno" class="mr-4 mb-4">
                <vs-button size="large" border @click="open_drawer('edit', 'vendor', item.title, item.spmno)">{{ item.title }}</vs-button>
              </div>
            </div>

            <div v-else class="text-center pb-4">尚無任何供應商</div>
          </div>
        </section>
      </div>

      <!-- 骨架屏 -->
      <div class="space-y-4" v-else>
        <section v-for="(item, index) in 2" :key="index" class="wrap">
          <div class="leftBlock">
            <skeleton class="mt-4" width="100px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="200px" height="10px" />
          </div>
          <div class="rightBlock">
            <div class="flex items-center justify-between">
              <skeleton width="100px" />
              <skeleton width="100px" />
            </div>
            <hr />
            <div class="flex items-center flex-wrap">
              <skeleton v-for="(item, index) in 3" :key="index" width="100px" class="mr-4 mb-4" />
            </div>
          </div>
        </section>
      </div>

      <!-- 側邊選單 -->
      <ValidationObserver v-slot="{ invalid }">
        <drawer>
          <template #header>
            <h1 v-if="isEdit">
              <span v-if="activating === 'tag'">編輯標籤</span>
              <span v-else-if="activating === 'vendor'">編輯供應商</span>
            </h1>
            <h1 v-else>
              <span v-if="activating === 'tag'">新增標籤</span>
              <span v-else-if="activating === 'vendor'">新增供應商</span>
            </h1>
          </template>

          <section class="flex flex-col justify-between h-full">
            <!-- 名稱 -->
            <div>
              <h4 class="h4 mb-1">名稱 *</h4>
              <div class="relative">
                <ValidationProvider mode="aggressive" name="名稱" rules="required|max:50" v-slot="{ errors }">
                  <vs-input v-if="activating === 'tag'" placeholder="例如：本月特價、季節限定..." v-model="title"></vs-input>
                  <vs-input v-else-if="activating === 'vendor'" placeholder="請輸入供應商名稱" v-model="title"></vs-input>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>

            <!-- 此標籤內的商品列表 -->
            <div v-if="isEdit">
              <div class="flex items-center justify-between mb-4">
                <h4 class="h4">
                  套用此<span v-if="activating === 'tag'">標籤</span><span v-else-if="activating === 'vendor'">供應商</span>的商品
                </h4>
                <!-- 查看全部按鈕，當為標籤時 -->
                <div v-if="products.length > 0">
                  <router-link
                    v-if="activating === 'tag' && sptno"
                    :to="{
                      name: 'products',
                      query: {
                        tags: sptno,
                      },
                    }"
                  >
                    <vs-button transparent color="success" size="mini"><span class="text-sm">查看全部</span></vs-button></router-link
                  >
                  <!-- 查看全部按鈕，當為供應商時 -->
                  <router-link
                    v-else-if="activating === 'vendor' && spmno"
                    :to="{
                      name: 'products',
                      query: {
                        vendors: spmno,
                      },
                    }"
                  >
                    <vs-button transparent color="success" size="mini"><span class="text-sm">查看全部</span></vs-button></router-link
                  >
                </div>
              </div>
              <div class="space-y-2" v-if="products.length > 0">
                <div v-for="(item, index) in products" :key="index" class="flex items-center space-x-2">
                  <div class="w-10 h-10 items-center justify-center flex border border-gray-border rounded-lg overflow-hidden">
                    <img v-if="item.img" class="w-full h-full object-cover" :src="item.img" />
                    <svg-icon v-else icon-class="product_tag" className="gray-border sm"></svg-icon>
                  </div>
                  <p class="text-limit-1">{{ item.title }}</p>
                </div>
                <!-- <div v-if="products.length >= 5" class="flex flex-col items-center justify-center space-y-1">
                  <span v-for="(dot, index) in 3" :key="index" class="w-1 h-1 bg-gray-border rounded-full inline-block"></span>
                </div> -->
              </div>
              <p v-else class="text-gray-divide text-center py-10">尚無任何商品</p>
            </div>
          </section>

          <template #footer>
            <section class="flex items-center justify-between w-full">
              <div>
                <vs-button v-show="isEdit" size="large" transparent color="danger" class="font-medium" @click="deleteDialog"
                  >刪除</vs-button
                >
              </div>

              <div class="flex justify-end space-x-4">
                <vs-button size="large" transparent class="font-medium" @click="close_drawer"> 取消 </vs-button>
                <vs-button v-if="isEdit" size="large" class="font-medium" :disabled="invalid" @click="saveHandler(invalid)" ref="saveBtn">
                  <span>儲存</span>
                </vs-button>
                <vs-button v-else size="large" class="font-medium" :disabled="invalid" @click="newHandler(invalid)" ref="newBtn">
                  <span>新增</span>
                </vs-button>
              </div>
            </section>
          </template>
        </drawer>
      </ValidationObserver>

      <!-- 刪除選單 dialog -->
      <vs-dialog overflow-hidden blur v-model="dialog" not-center>
        <template #header>
          <h1 class="text-base font-medium text-gray-text_dark">是否要刪除？</h1>
        </template>

        <div class="mb-3">
          <p class="text-sm text-gray-text_light">刪除後無法復原，確定仍要繼續？</p>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="dialog = false">取消</vs-button>
            <vs-button v-if="activating === 'tag'" @click="deleteTag" size="large" transparent color="danger" ref="deleteTagBtn"
              >刪除</vs-button
            >
            <vs-button v-if="activating === 'vendor'" @click="deleteVendor" size="large" transparent color="danger" ref="deleteVendorBtn"
              >刪除</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </div>
  </div>
</template>

<script>
import drawer from '@/components/drawer.vue'
export default {
  name: 'tags_vendors',
  components: {
    drawer,
  },
  data() {
    return {
      tagsList: [],
      vendorsList: [],
      products: [],
      is_API_loaded: false,
      title: '',
      isEdit: false,
      sptno: '', // 標籤碼
      spmno: '', // 廠商碼
      dialog: false,
      activating: '', // tag or vendor
    }
  },
  async created() {
    // 標籤和供應商都有取得，再顯示頁面
    // 讀取初始資料
    const res = await Promise.all([this.getTagList(), this.getVendorList()])

    // res會是陣列，若兩個都為true，關閉loading
    if (res.every((e) => e === true)) {
      this.is_API_loaded = true
    }
  },
  methods: {
    // 3.1.3.1 讀取標籤列表
    getTagList() {
      return this.$axios({
        url: 'front/store/tag/getTagList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.tagsList = res.data.Data
          return res.data.Success
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.1.3.2 標籤資料-讀取
    getTag(key) {
      return this.$axios({
        url: 'front/store/tag/getTagUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          sptno: key,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.products = res.data.Data.pitem
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.1.3.3 標籤資料-新增
    newTag(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.newBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/tag/setTagData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          title: this.title,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.close_drawer()
          this.getTagList()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.1.3.4 標籤資料-修改
    saveTag(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/tag/uTagData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          title: this.title,
          sptno: this.sptno,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.close_drawer()
          this.getTagList()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.1.3.5 標籤資料-刪除
    deleteTag() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteTagBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/tag/dTagData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          title: this.title,
          sptno: this.sptno,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close()
          this.dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.getTagList()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.1.4.1 讀取廠商列表
    getVendorList() {
      return this.$axios({
        url: 'front/store/manufacturer/getManufacturerList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.vendorsList = res.data.Data
          return res.data.Success
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.1.4.2 廠商資料-讀取
    getVendor(key) {
      this.$axios({
        url: 'front/store/manufacturer/getManufacturerUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spmno: key,
        },
      }).then((res) => {
        console.log(res, '3.1.4.2 廠商資料-讀取')
        if (res.data.Success) {
          this.products = res.data.Data.pitem
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.1.4.3 廠商資料-新增
    newVendor(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.newBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/manufacturer/setManufacturerData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          title: this.title,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.close_drawer()
          this.getVendorList()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.1.4.4 廠商資料-修改
    saveVendor(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/manufacturer/uManufacturerData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          title: this.title,
          spmno: this.spmno,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.close_drawer()
          this.getVendorList()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 3.1.4.5 廠商資料-刪除
    deleteVendor() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteVendorBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/manufacturer/dManufacturerData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          title: this.title,
          spmno: this.spmno,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close()
          this.dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.getVendorList()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 控制要儲存的是tag or vendor
    saveHandler(invalid) {
      if (invalid) return
      if (this.activating === 'tag') {
        this.saveTag()
      } else if (this.activating === 'vendor') {
        this.saveVendor()
      }
    },
    // 控制要新增的是tag or vendor
    newHandler(invalid) {
      if (invalid) return
      if (this.activating === 'tag') {
        this.newTag()
      } else if (this.activating === 'vendor') {
        this.newVendor()
      }
    },
    // 開啟刪除dialog
    deleteDialog() {
      this.dialog = true
    },
    // 開啟drawer選單
    open_drawer(status, activating, title, key) {
      console.log(status, activating, title, key)

      // status: edit or new
      // activating: tag or vendor
      this.activating = activating

      if (this.activating === 'tag') {
        this.sptno = key
        if (status === 'edit') this.getTag(key) // 讀取商品列表
      } else if (this.activating === 'vendor') {
        this.spmno = key
        if (status === 'edit') this.getVendor(key) // 讀取商品列表
      }

      if (status === 'edit') {
        this.isEdit = true
        this.title = title
      } else if (status === 'new') {
        this.isEdit = false
        this.title = ''
        this.sptno = ''
        this.spmno = ''
        this.products = []
      }
      this.$store.commit('DRAWER_TOGGLER', {
        name: 'DRAWER_TOGGLER',
      })
      this.$store.commit('OVERLAY_TOGGLER', true)
    },
    // 關閉drawer選單
    close_drawer() {
      // this.$store.commit('DRAWER_TOGGLER', {
      //   name: '',
      // })
      this.$store.dispatch('closeOverlay')
    },
  },
  // 如果drawer是開的，離開路由時把它關上。
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.drawer) {
      this.close_drawer()
      // this.$store.dispatch('closeOverlay')
    }
    next()
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftBlock {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0;
}

.rightBlock {
  @apply flex-grow h-fit bg-white p-4 pb-0 rounded-xl space-y-4;
}
</style>
